import { Action, Functionality } from '@eceos/arch';
import { DELETE, DELETE_TEMPORARY, INSERT, UPDATE } from './crud-actions';
import { NfeAction, VaccineApplicationsActions } from './actions';
import { ProductAction } from './actions/product-actions';
export class Feature implements Functionality {
  private readonly actionMap = new Map((this.actions.map(a => [a.id, a]) as unknown) as ReadonlyArray<[string, Action]>);

  constructor(public readonly id: string, public readonly actions: Action[] = []) {}

  getActionById(id: string) {
    return this.actionMap.get(id);
  }

  toJson() {
    return this.id;
  }

  public static fromJson(id: string) {
    return ALL_MAP.get(id);
  }

  public static all() {
    return ALL;
  }
}

/* global */
export const BANKS = new Feature('BANKS', [INSERT, UPDATE, DELETE_TEMPORARY]);

/* specific */
export const CURRENT_ENTERPRISE = new Feature('CURRENT_ENTERPRISE', [UPDATE, DELETE_TEMPORARY])

export const ACCOUNTS = new Feature('ACCOUNTS', [INSERT, UPDATE, DELETE_TEMPORARY]);
export const INSTALLMENT_POLICIES = new Feature('INSTALLMENT_POLICIES', [INSERT, UPDATE, DELETE_TEMPORARY]);
export const RATE_POLICIES = new Feature('RATE_POLICIES', [INSERT, UPDATE, DELETE_TEMPORARY]);
export const CHARGING_FLOWS = new Feature('CHARGING_FLOWS', [INSERT, UPDATE, DELETE, DELETE_TEMPORARY]);

export const NFE = new Feature('NFE', [...NfeAction.ALL, INSERT, UPDATE, DELETE_TEMPORARY]);
export const FISCAL_DOCUMENT_SERIES = new Feature('FISCAL_DOCUMENT_SERIES', [INSERT, UPDATE, DELETE_TEMPORARY]);
export const FISCAL_PRODUCTS = new Feature('FISCAL_PRODUCTS', [INSERT, UPDATE, DELETE, DELETE_TEMPORARY]);
export const FISCAL_SERVICES = new Feature('FISCAL_SERVICES', [INSERT, UPDATE, DELETE, DELETE_TEMPORARY]);
export const TAX_CATEGORIES = new Feature('TAX_CATEGORIES',[INSERT, UPDATE, DELETE, DELETE_TEMPORARY]);
export const ADDITION_FISCAL_CONFIG = new Feature('ADDITION_FISCAL_CONFIG',[INSERT, UPDATE, DELETE]);

export const PROFILES = new Feature('PROFILES', [INSERT, UPDATE,DELETE, DELETE_TEMPORARY]);
export const CLIENTS = new Feature('CLIENTS', [INSERT, UPDATE, DELETE_TEMPORARY]);
export const CARRIERS = new Feature('CARRIERS', [INSERT, UPDATE, DELETE_TEMPORARY]);
export const USERS = new Feature('USERS', [INSERT, UPDATE, DELETE, DELETE_TEMPORARY]);
export const PRODUCTS = new Feature('PRODUCTS', [...ProductAction.ALL, INSERT, UPDATE, DELETE, DELETE_TEMPORARY]);

export const OPERATABLE_UNITIES = new Feature('OPERATABLE_UNITIES', [INSERT, UPDATE, DELETE, DELETE_TEMPORARY]);

export const SALES_ORDER = new Feature('SALES_ORDER', [INSERT, UPDATE]);
export const SALES_ORDER_ACTIONS = new Feature('SALES_ORDER_ACTIONS', [INSERT, UPDATE, DELETE_TEMPORARY]);

export const SALES_ACTIONS = new Feature('SALES_ACTIONS', [INSERT, UPDATE, DELETE_TEMPORARY]);

export const DELIVERERS = new Feature('DELIVERERS', [INSERT, UPDATE, DELETE_TEMPORARY]);
export const DELIVERY_POLICIES = new Feature('DELIVERY_POLICIES', [INSERT, UPDATE, DELETE_TEMPORARY]);

/* vaccines */
export const VACCINE_APPLICATIONS = new Feature('VACCINE_APPLICATIONS', [...VaccineApplicationsActions.ALL,INSERT, UPDATE, DELETE]);
export const PATIENTS = new Feature('PATIENTS', [INSERT, UPDATE, DELETE_TEMPORARY]);

/* queue */
export const QUEUE_CATEGORIES = new Feature('QUEUE_CATEGORIES', [INSERT, UPDATE, DELETE, DELETE_TEMPORARY]);
export const QUEUES = new Feature('QUEUES', [INSERT, UPDATE, DELETE, DELETE_TEMPORARY]);

export const ALL = [
  CURRENT_ENTERPRISE,
  BANKS,
  ACCOUNTS,
  INSTALLMENT_POLICIES,
  RATE_POLICIES,
  CHARGING_FLOWS,
  NFE,
  FISCAL_DOCUMENT_SERIES,
  FISCAL_PRODUCTS,
  FISCAL_SERVICES,
  TAX_CATEGORIES,
  CLIENTS,
  CARRIERS,
  PROFILES,
  USERS,
  PRODUCTS,
  OPERATABLE_UNITIES,
  SALES_ORDER,
  SALES_ORDER_ACTIONS,
  ADDITION_FISCAL_CONFIG,
  /*vaccines*/
  VACCINE_APPLICATIONS,
  PATIENTS,
  /* queue */
  QUEUE_CATEGORIES,
  QUEUES,
  SALES_ACTIONS,
  DELIVERERS,
  DELIVERY_POLICIES
];

const ALL_MAP = new Map((ALL.map(f => [f.id, f]) as unknown) as ReadonlyArray<[string, Functionality]>);
